import React, { useEffect, useState, useCallback } from 'react';
import './login.css';
import OtpInput from 'react18-input-otp'
import apiService from '../../services/apiService';
import CustomToast from '../Toast';
import LoginFooter from "./loginFooter";
import AcceptInvitationComponent from "../layouts/accept_invitation";
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal'
import Messages from '../messages';
import { debounce } from '../../utils/debounce'; // Import the debounce function

const OtpVerification = ({ userDetails, retryAcceptProps, isUser }) => {
  const [otp, setOtp] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState('');
  const [inviteUser, setInviteUser] = useState(null)
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpTimer, setOtpTimer] = useState(120);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isContinueModalOpen, setContinueModalOpen] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [isContinue, setIsContinue] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const [message, setMessage] = useState('')
  const [isRedirected, setIsRedirected] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [otpData, setOtpData] = useState(null);
  const [showOtpComponent, setShowOtpComponent] = useState(false);
  const [loader, setLoader] = useState(false)
  const [retryAccept, setRetryAccept] = useState()
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [newOrgUserData, setNewOrgUserData] = useState()

  
  // useEffect(() => {
  //   setShowOtpComponent(false);
  //   const params = new URLSearchParams(location.search);
  //   const token = params.get('token');

  //   if (token) {
  //     handleCheckCourseExpiry(token);
  //   }
  // }, []);

  // const handleCheckCourseExpiry = useCallback(
  //   debounce(async (paramsToken) => {

  //   try {
  //       const data = {
  //         token: paramsToken,
  //         "userType": isUser === 'leaner-user' ? 'learner' : 'org-user'
  //       };

  //       const result = await apiService.post('check_course_expiry', data);

  //       if (result && result.status === "success") {

  //         console.log("result", result);

  //         setShowOtpComponent(true);
  //         setShowErrorPopup(false);
  //     } 
  //   } catch (error) {
  //     setShowOtpComponent(false);
  //     handleAPIError(error);
  //     setShowErrorPopup(true);
  //   } 
  // }, 300), // Adjust the debounce delay as needed
  // []
  // );

  const handleAPIError = (error) => {
      setShowErrorPopup(true)
      setMessage(error.message);
      openVideoModal();
  };

  // alert(isUser)
 const  debouncedVerifyToken = useCallback(
     debounce(async (paramsToken) => {
    // try {
    //   // const appToken = apiService.getAuthToken();
    //   setLoader(true)
    //   const data = {
    //     token: paramsToken,
    //     status: "ACCEPTED",
    //     dname: "OPPO",
    //     did: "2526"
    //   };
    //   setRetryAccept(data)
    //   const result = await apiService.post('accept-and-assign-course', data);
    //   if (result && result.status === "success") {
    //     setOtpData(result.data);
    //     setShowOtpComponent(true);
    //     CustomToast.success('OTP send successfully')
    //     setLoader(false)
    //   } else {
    //     setShowOtpComponent(false);
    //     setError('Failed to verify token');
    //     CustomToast.error('Failed to verify token');
    //     setLoader(false)
    //   }
    // } catch (error) {
    //   setShowOtpComponent(false);
    //   console.log(error);
    //   handleAPIError(error);
    //   CustomToast.error(error);
    //   setLoader(false)
    // }

    try {
      setLoader(true);
      const data = {
        token: paramsToken,
        status: "ACCEPTED",
        dname: "OPPO",
        did: "2526"
      };
      setRetryAccept(data);
      const endpoint = isUser === 'leaner-user' ? 'accept-and-assign-course' : 'accept-org';

      const result = await apiService.post(endpoint, data);
      if (result && result.status === "success") {

        if (isUser == 'leaner-user') {
          setOtpData(result.data);
          setShowOtpComponent(true);
          setUserInfo(result.data);
          getInviteUserDetails(result.data?.invite_id);
        }

        if (isUser == 'org-user') {
          result.data['type'] = 'org-user'
          setOtpData(result.data);
          setShowOtpComponent(true);
          setUserInfo(result.data);
          getOrgInviteUserDetails(result.data?.uid)
        }

        CustomToast.success('OTP sent successfully');
      } else {
        setShowOtpComponent(false);
        setError('Failed to verify token');
        CustomToast.error('Failed to verify token');
      }
    } catch (error) {
      setShowOtpComponent(false);
      handleAPIError(error);
      CustomToast.error(error);
    } finally {
      setLoader(false);
    }
  }, 300), // Adjust the debounce delay as needed
  []
);

  const openVideoModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRedirectTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, []);

  // useEffect(() => {
  // //  console.log("dsas", process.env.REACT_APP_ENV)
  //   if (redirectTimer === 0 && showErrorPopup) {
  //     // Redirect and close the modal when the timer reaches 0
  //     window.location.href = process.env.REACT_APP_LOGIN_URL
  //     // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
  //     setIsModalOpen(false);
  //   }
  // }, [redirectTimer, navigate]);

  const closeVideoModal = () => {
    setIsModalOpen(false);
  };

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 15);
    const remainingSeconds = seconds % 15;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const openContinueModal = () => {
    setContinueModalOpen(true);
  };

  const closeContinueModal = () => {
    setContinueModalOpen(false);
  };

  // useEffect(() => {
  //   setUserInfo(userDetails);
  //   if(userDetails && userDetails?.type == 'org-user') {
  //     getOrgInviteUserDetails(userDetails?.uid)
  //   } else {
  //     getInviteUserDetails(userDetails?.invite_id)
  //   }
  // }, [userDetails]);

  useEffect(() => {
    let timerId;

    if (otpTimer > 0) {
      timerId = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (otpTimer === 0) {
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showOTP, otpTimer]);

  const getInviteUserDetails = (invite_id) => {
    apiService.get(`get-invite-detail-by-id/${invite_id}`).then((inviteData) => {
      if (inviteData) {
        setInviteUser(inviteData.data)
      }
    }).catch((err) => {
      CustomToast.error(err)
    })
  }

  const getOrgInviteUserDetails = (uid) => {
    apiService.get(`get-org-invite-detail-by-id/${uid}`).then((inviteData) => {
      if (inviteData) {
        setInviteUser(inviteData.data)
      }
    }).catch((err) => {
      CustomToast.error(err)
    })
  }

  const handleOTPChange = (e) => {
    const otpValue = e.target.value;
    setOtp(otpValue);
  };
  // const redirectTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  // };

  const handleVerifyOTP = async () => {
    try {
      if (otp.length === 6 && /^\d+$/.test(otp)) {
        const obj = {
          uid: newOrgUserData ? newOrgUserData?.uid : userInfo?.uid,
          code: otp,
          invite_id: newOrgUserData ? newOrgUserData?.invite_id : userInfo?.invite_id,
        };
    
        const endpoint = userInfo?.type === 'org-user' ? 'verify-org-user-invite' : 'assign-course-to-org-user';

        const result = await apiService.post(endpoint, obj);

        if (result && result.status === 'success') {
          const token = result.data;
          const encodedToken = encodeURIComponent(JSON.stringify(token));

          CustomToast.success('User created successfully');

          const redirectURL =
          process.env.REACT_APP_ENV === 'development'
            ? endpoint === 'verify-org-user-invite'
              ? 'https://orgadmin.inclusional.com/'
              :  endpoint === 'assign-course-to-org-user' && result?.data?.is_org_user === true ? 'https://orgadmin.inclusional.com/learning_space/' : endpoint === 'assign-course-to-org-user' && result?.data?.is_org_user === false ? 'https://learn.inclusional.com/' : 'https://learn.inclusional.com/'
            : endpoint === 'verify-org-user-invite'
            ? 'https://orgadmin.inclusional.com/'
            : endpoint === 'assign-course-to-org-user' && result?.data?.is_org_user === true ? 'https://orgadmin.inclusional.com/learning_space/' : endpoint === 'assign-course-to-org-user' && result?.data?.is_org_user === false ? 'https://learn.inclusional.com/' : 'https://learn.inclusional.com/';
          window.location.href = `${redirectURL}?ref=${encodedToken}`;
          setInviteUser(null)
          // setIsRedirected(true)
        } else {
          handleError(result);
        }
      } else {
        setError('Please enter a valid 6-digit OTP.');
        CustomToast.error('Please enter a valid 6-digit OTP.');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error && error.message && error.message.includes('Failed to assign course: Invalid request or code!')) {
      CustomToast.info('Incorrect OTP');
    } else if (error && error.message && error.message.includes('Course already accepted')) {
      setMessage('Course already accepted please try login')
    }
    else {
      CustomToast.error('Failed to verify OTP. Please try again.');
    }
  };

  const handleResendOTP = async () => {
    setOtp('');
    try {

      const endpoint = userInfo?.type === 'org-user' ? 'accept-org' : 'accept-and-assign-course';
      const result = await apiService.post(endpoint, retryAccept);
      
      if (result && result.status === "success") {
        setOtpTimer(120);
        setShowOTP(true);
        setNewOrgUserData(result.data);        
        CustomToast.success('OTP send successfully')
      } else {
        CustomToast.error('Failed to verify token');
      }
    } catch (error) {
      CustomToast.error(error);
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 15);
    const remainingSeconds = seconds % 15;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const redirectToLogin = () => {
    window.location.href = process.env.REACT_APP_LOGIN_URL
    // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
    // setIsModalOpen(false);
  }

  // useEffect(() => {
  //   if (redirectTimer === 0 && showErrorPopup) {
  //     // Redirect and close the modal when the timer reaches 0
  //     const params = new URLSearchParams(location.search);
  //     const token = params.get('token');
  //     if (token) {
  //       window.location.href = `${process.env.REACT_APP_LOGIN_URL}?token=${token}`
  //     } else {
  //       window.location.href = process.env.REACT_APP_LOGIN_URL
  //     }
  //     // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
  //     setIsModalOpen(false);
  //   }
  // }, [redirectTimer, navigate]);

  const handleContinue = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    setIsContinue(true);
    closeContinueModal();

    let tokanData = '';
    if (token) {
      debouncedVerifyToken(token);
    } else {
     // navigate('/error');
      setShowErrorPopup(true);
    }

    // if(userDetails && userDetails?.type == 'org-user') {
    //   getOrgInviteUserDetails(userDetails?.uid)
    // } else {
    //   getInviteUserDetails(userDetails?.invite_id)
    // }
  };

  return (
    <>
      {/* {console.log("dfssds",inviteUser)} */}
      {/* {inviteUser ?  */}
 
      <div className='login'>
        <div className='login-bottom'>
          <div className='container-fluid p-0'>
            <div className='bg-login'>
              <img src="/images/logo.svg" className='logo'></img>
              {isContinue && (
                <div className='container'>
                  <div className='row d-flex justify-content-between w-100 h-100 m-auto'>
                    <div className='col-md-6'>
                      <img src='/images/login-bg.png' alt="login" className='w-100 d-none p-5' />
                    </div>
                    <div className='col-md-6'>
                      <div className='login-otp py-2 d-flex flex-column justify-content-center'>
                        <div className='login-details text-center d-flex flex-column justify-content-center align-items-center mb-lg-3 mb-3'>
                          <div className='org-logo'>
                            <img src={inviteUser?.org_image || ''} className='w-100 py-4 mx-auto' />
                          </div>
                          <h3 className='login-title fw-bold font-26 my-lg-3 my-0'>Hi, {inviteUser?.name || ''}</h3>
                          <p className='mb-0 font-18 text-center px-3'>
                            Welcome to Inclusional! Please verify your account by entering the OTP sent to <strong> {inviteUser?.email || ''}</strong>.
                          </p>
                        </div>

                        <div className="otp-input justify-content-center d-flex align-items-center">
                          <OtpInput
                            value={otp}
                            onChange={(otp) => handleOTPChange({ target: { value: otp } })}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle={{
                              border: "1px solid #CCC",
                              borderRadius: "7px",
                              width: "69px",
                              height: "67px",
                              fontSize: "20px",
                              color: "#000",
                              fontWeight: "400",
                              caretColor: "black",
                              margin: "10px",
                              outline: 'none',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>

                        <p className='resend-otp px-3 d-flex justify-content-center align-items-center mb-lg-0 mb-4'>
                          <span className='login-resend'>
                            <button
                              type="button"
                              className="btn btn-link primary_color text-dark-grey px-2 py-0 text-decoration-none"
                              onClick={handleResendOTP}
                              disabled={otpTimer > 0}
                            >
                              You can resend OTP in
                            </button>
                          </span>
                          <span className='login-time otp-timer'>{formatTime(otpTimer)}</span>
                        </p>

                        <div className='text-center w-100'>
                          <button className='btn btn-md btn-primary otp-btn py-lg-2 py-1 mt-lg-4 mt-0' onClick={handleVerifyOTP}>
                            Confirm
                          </button>
                        </div>

                        {/*error && <p className='login-error mt-2'>{error}</p>*/}
                        <p className='login-note'>By proceeding you agree to our <a href='/term' target='_blank' className='login-term text-decoration-none'>Terms and Conditions</a> and <a href='/privacy' target='_blank' className='login-term text-decoration-none'>Privacy Policy</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={isContinueModalOpen}
          onRequestClose={closeContinueModal}
          contentLabel="Modal"
          className='continue-modal bg-white'
        >
          <div className='d-flex flex-column justify-content-center align-items-center mb-lg-4 mb-3'>
            <h3 className='font-26 mb-lg-4 mb-2 fw-semibold mx-auto'>Welcome to Inclusional!</h3>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-start font-16'>
            <p className='mb-lg-4 mb-3 fw-semibold'>Hello{inviteUser?.name || ''},</p>
            <p>We're thrilled to have you join Inclusional. To ensure we provide you with a personalised experience, we kindly request some key information. Your organisation admin has already provided us with your name and email, which are mandatory. </p>
            <p>While these two details are required, you have the option to enhance your profile by sharing additional information such as your phone number, gender, age group, profile photo, designation, department and location. We believe that this additional data will help us tailor our content and resources to better suit your needs.</p>
            <p>At Inclusional, we prioritise your privacy. Your data is stored securely, and our practices align with the highest standards of data protection. If at any point you wish to delete your information from our system, simply reach out to your organisation admin, who will process your request. Rest assured, any deleted data will be removed within 30 days, or as stipulated by relevant laws. </p>
            <p>Ready to embark on your Inclusional journey? Click "Continue" to explore a wealth of resources tailored just for you! </p>
            <div className='d-flex justify-content-between align-items-center w-100 mt-lg-3 mt-2'>
              <button onClick={handleContinue} className='btn btn-md btn-primary py-2 mx-auto continue-btn'>Continue</button>
            </div>
          </div>
        </Modal>
        <LoginFooter></LoginFooter>
      </div> 
      {/* :  
       <> <Messages
         modalMessage={message}
         redirectTimer={redirectTimer}
       /> </>  */}
       
      {/* {isRedirected && <Messages errorMessage={'Go to login'} />} */}
    </>
  );
}

export default OtpVerification;