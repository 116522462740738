import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './manageUsers.css';
import RevokeUserModal from '../modal/revokeUserModal';
import DeactivateUserModal from '../modal/deactivateUserModal';
import ActivateUserModal from '../modal/activateUserModal';
import InviteUserModal from '../modal/inviteUserModal';
import NotifyUserModal from '../modal/notifyUserModal';
import PushUserModal from '../modal/pushUserModal';
import CustomSelect from '../customSelect/customSelect';
import { useNavigate, useLocation} from 'react-router-dom';
import apiService  from '../../../services/apiService';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';

const EditProfile = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [revokeModalIsOpen, setRevokeModalIsOpen] = useState(false);
    const [activateModalIsOpen, setActivateModalIsOpen] = useState(false);
    const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
    const [notifyModalIsOpen, setNotifyModalIsOpen] = useState(false);
    const [pushModalIsOpen, setPushModalIsOpen] = useState(false);
    const [userInfo, setUserData] = useState(null);
    const [basicInfo, setBasicInfo] = useState(null);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [uid, setUid] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const userData = location.state?.userData;
    const [updateEmailOrMobileFlag, setUpdateEmailOrMobileFlag] = useState(false);
   // localStorage.setItem('preSelectedCourseObj', localStorage.getItem('selectedCourseObj'))

    const fetchData = async (userData) => {
        try {
          setLoader(true);
          const token = apiService.getAuthToken();
          const result = await apiService.get(`get-org-manage-user-info?email=${encodeURIComponent(userData?.email)}&mobile=${userData?.mobile}`, token);
          if (result && result.status === 'success') {
            setUid(result?.data.uid)
            setUserData(result.data);
            setName(result.data.name)
            setEmail(result.data.email)
            setSelectedGender(result.data.gender)
            setPhone(result.data.mobile || userData?.mobile)
            setSelectedAgeGroup(result.data.age_group)
            setDesignation(result.data.designation || userData?.designation)
            setDepartment(result.data.department || userData?.department)
            setUserLocation(result.data.location || userData?.location)
            setLoader(false);
          }
        } catch (error) {
          setError('Error fetching user data');
          console.error(error);
          setLoader(false);
          CustomToast.error(error)
        }
      };
    
      useEffect(() => {
        setBasicInfo(userData);
        fetchData(userData);
      }, []);

      const ageGroupOptions = [
        { value: 'Boomers: 1946 - 1964', label: 'Boomers: 1946 - 1964' },
        { value: 'Gen X: 1965 - 1980', label: 'Gen X: 1965 - 1980' },
        { value: 'Millenials: 1981 - 1996', label: 'Millenials: 1981 - 1996' },
        { value: 'Gen Z: 1997 - 2012', label: 'Gen Z: 1997 - 2012' },
        { value: 'Alpha: 2013 - 2025', label: 'Alpha: 2013 - 2025' },
    ];

    const genderOptions = [
        { value: 'Woman', label: 'Woman' },
        { value: 'Man', label: 'Man' },
        { value: 'Others', label: 'Others' },
        { value: 'Prefer not to state', label: 'Prefer not to state'},
      ];

    const handleClose = () => {
        navigate('/manage_user')
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (newEmail === null || newEmail === undefined || newEmail === '' || newEmail === '-') {
            setUpdateEmailOrMobileFlag(false);
        } else {
            setUpdateEmailOrMobileFlag(true);
        }
       // validateEmail(newEmail);
    };
    
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(email);
        setEmailError(isValid ? '' : 'Please enter a valid email address.');
        return isValid
      };

    const handleAgeGroupChange = (value) => {
        setSelectedAgeGroup(value);
    };

    const handleGenderChange = (value) => {
        setSelectedGender(value);
    };

    const openRevokeModal = () => {
        setRevokeModalIsOpen(true);
    };

    const closeRevokeModal = () => {
        setRevokeModalIsOpen(false);
    };

    const openActivateModal = () => {
        setActivateModalIsOpen(true);
    };

    const closeActivateModal = () => {
        setActivateModalIsOpen(false);
    };

    const pushToComplete = () => {
        setPushModalIsOpen(true);
    };

    const notifyToStart = () => {
        setNotifyModalIsOpen(true);
    };

    const openInviteModal = () => {
        setInviteModalIsOpen(true);
    };

    const closeInviteModal = () => {
        setInviteModalIsOpen(false);
    };

    const closeNotifyModal = () => {
        setNotifyModalIsOpen(false);
    };

    const openDeactivateModal = () => {
        setDeactivateModalIsOpen(true);
    };

    const closeDeactivateModal = () => {
        setDeactivateModalIsOpen(false);
    };

    const closePushModal = () => {
        setPushModalIsOpen(false);
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        setPhone(newPhoneNumber);
        if (newPhoneNumber === null || newPhoneNumber === undefined || newPhoneNumber === '' || newPhoneNumber === '-') {
            setUpdateEmailOrMobileFlag(false);
        } else {
            setUpdateEmailOrMobileFlag(true);
        }
        // validatePhone(newPhoneNumber);
    };

    const validatePhone = (phone) => {

        if (isNaN(phone)) {
            setPhoneError('Please enter a valid phone number.');
            return;
        }

        const phonePattern = /^[0-9]{10}$/;
        const isValid = phonePattern.test(phone);
        if (!isValid) {
            setPhoneError('Please enter a valid 10-digit phone number.');
        } else {
            setPhoneError('');
        }
        return isValid
    };

    const titleCase = (str) => {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    const handleDepartmentChange = (e) => {
        const newDepartment = e.target.value;
        setDepartment(titleCase(newDepartment));
    };

    const handleDesignationChange = (e) => {
        const newDesignation = e.target.value;
        setDesignation(titleCase(newDesignation));
    };

    const handleLocationChange = (e) => {
        const newUserLocation = e.target.value;
        setUserLocation(titleCase(newUserLocation));
    };

    const validateEmailOrPhone = (email, phone) => {
        if (!email && !phone) {
            CustomToast.error('Email ID or Mobile is a mandatory field.');
            return false;
        }
        return true; 
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        // Create FormData object

        if (email && !validateEmail(email)) {
            return
        }
        if (phone && !validatePhone(phone)) {
            return;
        }
        if(!validateEmailOrPhone(email, phone)){
            return;
        }
        const updateForm = {
            "updateInvite" : true,
            "updateEmailOrMobile" : updateEmailOrMobileFlag ? true : false 
        }

        const updateForm1 = {
            uid: uid
        }

        if(name && name !=  "" && name != "null" && name != "undefined") {
            updateForm['name'] = name.trim()
            updateForm1['name'] = name.trim()
        }
        if(phone && phone !=  "" && phone != "null" && phone != "undefined") {
            updateForm['mobile'] = phone
            updateForm1['mobile'] = phone
        }
        else {
            updateForm['mobile'] = null;
            updateForm1['mobile'] = null;
        }
        if(email && email !=  "" && email != "null" && email != "undefined") {
            updateForm['email'] = email
            updateForm1['email'] = email
        } else {
            updateForm['email'] = null;
            updateForm1['email'] = '-';
        }
        if(selectedAgeGroup && selectedAgeGroup !=  "" && selectedAgeGroup != "null" && selectedAgeGroup != "undefined") {
            // updateForm['age_group'] = selectedAgeGroup
            updateForm1['age_group'] = selectedAgeGroup
        }
        if(department && department !=  "" && department != "null" && department != "undefined") {
            updateForm['department'] = department.trim()
            updateForm1['department'] = department.trim()
        } 
        else {
            updateForm['department'] = null;
            updateForm1['department'] = '-';
        }
        if(designation && designation !=  "" && designation != "null" && designation != "undefined") {
            updateForm['designation'] = designation.trim()
            updateForm1['designation'] = designation.trim()
        } 
        else {
              updateForm['designation'] = null;
              updateForm1['designation'] = '-';
          }
        if(userLocation && userLocation !=  "" && userLocation != "null" && userLocation != "undefined") {
            updateForm['location'] = userLocation.trim()
            updateForm1['location'] = userLocation.trim()
        } 
        else {
              updateForm['location'] = null;
              updateForm1['location'] = '-';
          }
        if(selectedGender && selectedGender !=  "" && selectedGender != "null" && selectedGender != "undefined") {
            // updateForm['gender'] = selectedGender
            updateForm1['gender'] = selectedGender
        }

        if(basicInfo?.merged_status === 'REVOKED' || basicInfo?.merged_status === 'INVITED'){
            try {
                const token = apiService.getAuthToken();
                const response = await apiService.putWithoutFormData(`update-invite/${basicInfo?.invite_id}`, updateForm, token);
                if (response && response.status === 'success') {
                    if(updateForm?.updateEmailOrMobile === true){
                        openInviteModal();
                        CustomToast.success('User updated successfully.')
                    } else {
                        CustomToast.success('User updated successfully.')
                        navigate("/manage_user")
                    }
                } else {
                    setError(error.message);
                    CustomToast.error(error.message);
                }
                setLoader(false);
            } catch (error) {
                setLoader(false);
                CustomToast.error(error.message);
                setError(error.message);
            }
        } else {
            try {
                const token = apiService.getAuthToken();
                const response = await apiService.putWithoutFormData('update-org-manage-user-info', updateForm1, token);
                if (response && response.status === 'success') {
                    CustomToast.success('User updated successfully.')
                    navigate("/manage_user")
                } else {
                    setError(error.message);
                    CustomToast.error(error.message);
                }
                setLoader(false);
            } catch (error) {
                setError(error.message);
                setLoader(false);
                CustomToast.error(error.message);
            }
        }


    };

    const sendInvite = async() => {
        try {
            const updateForm = {
                invite_id: basicInfo.invite_id,
                org_id: basicInfo?.org_id || "8fbf46bf-426a-4977-ab86-e419e4ee8892",
                org_course_id: basicInfo?.org_course_id || "66aed4b0-e7ab-4c48-ade2-fca43d4e6d1e",
            }
            const token = apiService.getAuthToken();
            const response = await apiService.post('course-reinvite', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Reminder sent successfully')
              navigate("/manage_user")
            } else {
              setError('Failed to update profile');
              CustomToast.error('Error while sending invitaion')
            }
            setLoader(false);
          } catch (error) {
            setError('An error occurred while updating profile');
            console.error(error);
            setLoader(false);
            CustomToast.error('Error while sending invitaion')
          }
    }

    const sendNotify = async() => {
        const updateForm = {
            name: name,
            org_id: basicInfo?.org_id || "8fbf46bf-426a-4977-ab86-e419e4ee8892",
            course_id: basicInfo?.course_id || "515f65e7-cee9-46e1-aca4-27fe6e94509c",
            user_id: basicInfo?.user_id || "46e593f1-95e7-4270-a786-7cf2a64a5b28 ",
            action: "notify" //push
        }

        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Notify to start course.')
                navigate("/manage_user")
            } else {
              setError('Failed to update profile');
            }
            setLoader(false);
          } catch (error) {
            setError('An error occurred while updating profile');
            setLoader(false);
            console.error(error);
            CustomToast.error('Error while notify user')
          }
    }

    const sendPush = async() => {
        const  updateForm = {
            name: name,
            org_id: basicInfo?.org_id || "8fbf46bf-426a-4977-ab86-e419e4ee8892",
            course_id: basicInfo?.course_id || "515f65e7-cee9-46e1-aca4-27fe6e94509c",
            user_id: basicInfo?.user_id || "46e593f1-95e7-4270-a786-7cf2a64a5b28 ",
            action:"push" //push
        }

        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Push to complete course.')
                navigate("/manage_user")
            } else {
              setError('Failed to update profile');
            }
            setLoader(false);
          } catch (error) {
            setError('An error occurred while updating profile');
            setLoader(false);
            console.error(error);
            CustomToast.error('Error while notify user')
          }
    }

    const revokeUser = async() => {
        try {
            const updateForm = {
                type: "revoke",
                users: [{
                    user_id: basicInfo?.user_id,
                    org_id: basicInfo?.org_id,
                    org_course_id: basicInfo?.org_course_id,
                    invite_id: basicInfo?.invite_id
                }]

            }
            const token = apiService.getAuthToken();
            const response = await apiService.putWithoutFormData('revoke_and_activate', updateForm, token);
            console.log(response);
            if (response && response.status === 'success') {
                CustomToast.success('User Revoked.')
                navigate("/manage_user")
                closeRevokeModal()
            } else {
              setError('Failed to update profile');
              // CustomToast.error('Error while revoke user')
              // closeRevokeModal()
            }
            setLoader(false);
          } catch (error) {
            // setError('An error occurred while updating profile');
            // console.error(error);
            // setLoader(false);
            // CustomToast.error('Error while revoke user')
            // closeRevokeModal()
          }
    }

    const activeUser = async() => {
          try {
            const updateForm = {
                type: "active",
                users: [{
                    user_id: basicInfo?.user_id,
                    org_id: basicInfo?.org_id,
                    org_course_id: basicInfo?.org_course_id,
                    invite_id: basicInfo?.invite_id
                }]
            }
            const token = apiService.getAuthToken();
            const response = await apiService.putWithoutFormData('revoke_and_activate', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('User activated.')
                navigate("/manage_user")
                closeActivateModal();
            } else {
              setError('Failed to update profile');
              CustomToast.error('Error while activating user')
              closeActivateModal();
            }
            setLoader(false);
          } catch (error) {
            console.error(error);
            if(error?.message.includes('Activate action failed due to license exhausted')) {
                setLoader(false);
                CustomToast.info('Activate action failed due to license exhausted')
                closeActivateModal();
            } else {
                setLoader(false);
                CustomToast.error('Error while activating user')
                closeActivateModal();
            }
          }
    }

    const deactivateUser = async() => {
        try {
          const updateForm = {
              type: "deactivated",
              users: [{
                  user_id: basicInfo?.user_id,
                  org_id: basicInfo?.org_id,
                  org_course_id: basicInfo?.org_course_id
              }]
          }
          const token = apiService.getAuthToken();
          const response = await apiService.putWithoutFormData('revoke_and_activate', updateForm, token);
          if (response && response.status === 'success') {
              CustomToast.success('User deactivated.')
              navigate("/manage_user")
              closeDeactivateModal();
          } else {
            setError('Failed to update profile');
            CustomToast.error('Error while activating user')
            closeDeactivateModal();
          }
          setLoader(false);
        } catch (error) {
          setError('An error occurred while updating profile');
          console.error(error);
          setLoader(false);
          CustomToast.error('Error while activating user')
          closeDeactivateModal();
        }
  }

  let orgId = localStorage.getItem("ORGID");

    return (
        <div className='nosidebar-page__main'>
            <div className="dashboard">
                <div className='profile-form-wrapper m-auto'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                        <h3 className='font-weight-600 font-20'>Edit User</h3>
                        {loader ? <Loader />
                        :  <div className='d-flex justify-content-end align-items-center'>
                        {basicInfo?.merged_status === 'revoked' || basicInfo?.merged_status === 'REVOKED' || basicInfo?.merged_status === 'not_started'  || basicInfo?.merged_status === 'INVITED' ||  basicInfo?.merged_status === 'notstarted' || basicInfo?.merged_status === 'completed' || basicInfo?.merged_status === 'inprogess' || basicInfo?.merged_status === 'inprogress' || basicInfo?.merged_status === 'inprogress' || basicInfo?.merged_status === 'deactivated' || basicInfo?.merged_status === 'deactivate' ? (
                                <>
                                    {basicInfo?.merged_status === 'revoked' || basicInfo?.merged_status === 'REVOKED' || (basicInfo?.merged_status === 'deactivate' || basicInfo?.merged_status === 'deactivated') ? (
                                        <>
                                            <button type="button" onClick={openActivateModal} className="btn btn-md btn-activate me-2">Activate</button>
                                            <Modal
                                                isOpen={activateModalIsOpen}
                                                contentLabel="Active Modal"
                                            >
                                            <ActivateUserModal
                                                onRequestClose={closeActivateModal}
                                                onConfirm={activeUser}
                                            />
                                            </Modal>
                                        </>
                                    ) : basicInfo?.merged_status == 'INVITED' ? (
                                        <>
                                            <button type="button" onClick={openRevokeModal} className="btn btn-md btn-danger me-2">Revoke</button>
                                            <Modal
                                                isOpen={revokeModalIsOpen}
                                                contentLabel="Active Modal"
                                            >
                                            <RevokeUserModal
                                                onRequestClose={closeRevokeModal}
                                                onConfirm={revokeUser}
                                            />
                                            </Modal>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button" onClick={openDeactivateModal} className="btn btn-md btn-danger me-2">Deactivate</button>
                                            <Modal
                                                isOpen={deactivateModalIsOpen}
                                                contentLabel="Deactivate Modal"
                                            >
                                            <DeactivateUserModal
                                                onRequestClose={closeDeactivateModal}
                                                onConfirm={deactivateUser}
                                            />
                                            </Modal>
                                        </>
                                    )}
                                </>
                            ) : null}

                           { (basicInfo?.merged_status != 'REVOKED' && basicInfo?.merged_status != 'completed' &&  basicInfo?.merged_status != 'deactivate') && (<button type="button" className="btn btn-md btn-primary reminders dropdown-toggle pe-5" data-bs-toggle="dropdown" aria-expanded="false">
                                Reminder
                            </button>) }
                            <ul class="dropdown-menu">
                                {basicInfo?.merged_status == 'INVITED' && (
                                    <li><a class="dropdown-item" onClick={openInviteModal}>Resend Invite</a></li>
                                )}
                                {/* {basicInfo?.merged_status === 'revoked' || basicInfo?.merged_status === 'REVOKED'  && (
                                    <li><a class="dropdown-item" onClick={openInviteModal}>Resend Invite</a></li>
                                )} */}
                                {/* {basicInfo?.merged_status == 'deactivated' ||  basicInfo?.merged_status == 'deactivate'  && (
                                    <li><a class="dropdown-item" onClick={openInviteModal}>Resend Invite</a></li>
                                )} */}
                                {basicInfo?.merged_status === 'notstarted' && (
                                    <li><a class="dropdown-item" onClick={notifyToStart}>Notify To Start</a></li>
                                )}
                                {basicInfo?.merged_status === 'inprogess' || basicInfo?.merged_status === 'inprogress' && (
                                    <li><a class="dropdown-item" onClick={pushToComplete}>Push To Complete</a></li>
                                )}
                            </ul>

                            <Modal
                                isOpen={inviteModalIsOpen}
                                onRequestClose={closeInviteModal}
                                contentLabel="Invite Modal"
                            >
                                <InviteUserModal onRequestClose={closeInviteModal} onConfirm={sendInvite} />
                            </Modal>

                            <Modal
                                isOpen={notifyModalIsOpen}
                                onRequestClose={closeNotifyModal}
                                contentLabel="Invite Modal"
                            >
                                <NotifyUserModal  onRequestClose={closeNotifyModal} onConfirm={sendNotify} />
                            </Modal>

                            <Modal
                                isOpen={pushModalIsOpen}
                                onRequestClose={closePushModal}
                                contentLabel="Invite Modal"
                            >
                                <PushUserModal onRequestClose={closePushModal} onConfirm={sendPush} />
                            </Modal>

                        </div>}
                       
                    </div>
                    {loader ? <Loader />
                  :  <div className="card p-lg-3 p-0">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className='row d-flex justify-content-between '>
                                <div className="col-lg-6 form-group mb-3">
                                    <label className='mb-2'>Name*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Name'
                                        value={name}
                                        name="name"
                                        onChange={(e) => setName(e.target.value)}
                                        required />
                                </div>
                                <div className="col-lg-6 form-group mb-3">
                                    <label className='mb-2'>Email Id</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder='Org. Email Id'
                                        value={email}
                                        name="email"
                                        onChange={handleEmailChange}
                                        disabled={basicInfo && basicInfo?.merged_status === 'not_started' || basicInfo?.merged_status === 'notstarted' || basicInfo?.merged_status === 'completed' || basicInfo?.merged_status === 'inprogess' || basicInfo?.merged_status === 'inprogress' || basicInfo?.merged_status === 'inprogress' ? true : false}
                                        />
                                    {emailError && <p className="error">{emailError}</p>}
                                </div>
                          
                                <div className="col-lg-6 form-group mb-3">
                                    <label className='mb-2'>Mobile</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='xxxxx xxxxx'
                                        value={phone}
                                        name="phone"
                                        onChange={handlePhoneChange}
                                        disabled={basicInfo && basicInfo?.merged_status === 'not_started' || basicInfo?.merged_status === 'notstarted' || basicInfo?.merged_status === 'completed' || basicInfo?.merged_status === 'inprogess' || basicInfo?.merged_status === 'inprogress' || basicInfo?.merged_status === 'inprogress' ? true : false}
                                         />
                                    {phoneError && <p className="error">{phoneError}</p>}
                                </div>
                                {/* {orgId && orgId != '7c13b617-55a1-4ea6-810a-7bf05aaf52af' ? <>
                                {basicInfo && (basicInfo?.merged_status != "INVITED" && basicInfo?.merged_status != "REVOKED") ? <div className="col-lg-6 form-group age mb-3">
                                    <label htmlFor="ageSelect" className='mb-2'>Age Group</label>
                                    <CustomSelect
                                        name="selectedAgeGroup"
                                        placeholder="Choose your Age Group"
                                        options={ageGroupOptions}
                                        value={selectedAgeGroup}
                                        onChange={handleAgeGroupChange}
                                    />
                                </div> : null}
                        
                               {console.log("basicInfo======>", basicInfo, (basicInfo && basicInfo?.merged_status != "INVITED" || basicInfo?.merged_status != "REVOKED" ))}
                               { basicInfo && (basicInfo?.merged_status != "INVITED" && basicInfo?.merged_status != "REVOKED") ? <div className="col-lg-6 form-group gender mb-3">
                                    <label htmlFor="genderSelect" className='mb-2'>Gender</label>
                                    <CustomSelect
                                        name="selectedGender"
                                        placeholder='Select Gender'
                                        options={genderOptions}
                                        value={selectedGender}
                                        onChange={handleGenderChange}
                                    />
                                </div> : null} </> : ' '} */}

                                <div className="col-lg-6 form-group mb-3">
                                    <label className='mb-2'>Department</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='e.g. IT'
                                        value={department}
                                        name="department"
                                        onChange={handleDepartmentChange}
                                    />
                                </div>
                          
                                <div className="col-lg-6 form-group mb-3">
                                    <label className='mb-2'>Designation</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='e.g. Designer'
                                        value={designation}
                                        name="designation"
                                        onChange={handleDesignationChange}
                                    />
                                </div>

                                <div className="col-lg-6 form-group mb-3 location">
                                    <label className='mb-2'>Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='e.g. Pune'
                                        value={userLocation}
                                        name="userLocation"
                                        onChange={handleLocationChange}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <button type='button' onClick={handleClose} className='btn btn-secondary w-100 me-2'>Cancel</button>
                                <button type='submit' className='btn btn-primary w-100 ms-3'>Save</button>
                            </div>
                        </form>
                    </div>
                    </div>}
                </div>
            </div>
        </div >

    )

}

export default EditProfile;